@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.item {
  margin: 0 115.5px;
  margin-bottom: 60px;
  @include screen('desktop_sm') {
    margin: 0 63.5px;
    margin-bottom: 60px;
  }
  @include screen('desktop_annual_lg') {
    margin: 0;
    margin-bottom: 60px;
  }
  @include screen('mobile_annual_xl') {
    margin: 0;
    margin-bottom: 40px;
  }
  &.first {
    width: 100%;
    margin: 0;
    margin-bottom: 110px;
    @include screen('mobile_annual_xl') {
      margin: 0;
      margin-bottom: 40px;
    }
  }

  &.first {
    .main-content {
      padding: 12.5px 12.5px 12.5px 222.5px;
      background: $firstItemGardient;
      @include screen('mobile_annual_xl') {
        padding: 15px;
      }

      .general-info {
        display: flex;
        align-items: flex-start;

        @include screen('mobile_annual_xl') {
          width: 100%;
          flex-direction: column;
        }

        .image {
          margin-left: 30px;

          @include screen('mobile_annual_xl') {
            position: relative;
            width: 100%;
            margin-left: 0;
            padding-bottom: 100%;
          }

          img {
            display: block;
            width: 320px;
            height: 320px;
            object-fit: cover;

            @include screen('mobile_annual_xl') {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }

            @include screen('mobile_annual_md') {
              height: 100%;
            }

            @include screen('mobile_annual_sm') {
              height: 100%;
            }
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include screen('mobile_annual_xl') {
            position: relative;
            width: 100%;
            padding-top: 20px;
          }
          @include screen('mobile_annual_md') {
            padding-top: 14px;
          }
          .info-wrapper {
            @include screen('mobile_annual_xl') {
              padding-bottom: 48px;
            }
            .song-name {
              padding-bottom: 6px;
              font-size: 54px;
              line-height: 50px;
              @include screen('mobile_annual_xl') {
                padding-bottom: 6px;
                font-size: 34px;
                line-height: 30.5px;
              }
            }
            .artist-name {
              padding-bottom: 10px;
              font-size: 26px;
              line-height: 26px;
              @include screen('mobile_annual_xl') {
                padding-bottom: 6px;
                font-size: 21px;
                line-height: 23.49px;
              }
            }
            .release-date {
              font-size: 20px;
              line-height: 23.49px;
              @include screen('mobile_annual_xl') {
                padding-bottom: 6px;
                font-size: 16px;
                line-height: 23.49px;
              }
            }
          }
          .fist-places-number {
            display: none;
            @include screen('mobile_annual_xl') {
              display: block;
              position: absolute;
              left: -5px;
              top: -137px;
              font-size: 261px;
              line-height: 208.8px;
              color: #fff;
              font-family: $secondary-font;
            }
            @include screen('mobile_annual_md') {
              top: -150px;
            }
          }
          .animated-first-place {
            display: none;
            @include screen('mobile_annual_xl') {
              display: block;
              position: absolute;
              width: 380px;
              height: 592px;
              top: -330px;
              left: -136px;
              transform: scale(0.49);
              background-image: url('src/assets/images/content/sprite_vertical.png');
              animation: firstPlace 5s steps(80) infinite;
              @include screen('mobile_annual_md') {
                top: -349px;
                left: -136px;
                transform: scale(0.59);
              }
            }
          }
        }
      }

      .number {
        top: -135px;
        left: 45px;
        font-size: 445.8px;
        line-height: 509.36px;
      }

      .animated-first-place {
        position: absolute;
        width: 380px;
        height: 592px;
        top: -170px;
        left: -41px;
        background-image: url('src/assets/images/content/sprite_vertical.png');
        animation: firstPlace 5s steps(80) infinite;

        @include screen('mobile_annual_xl') {
          display: none;
        }
      }
    }
  }

  &.second,
  &.sixth,
  &.tenth {
    .main-content {
      background: $secondTypeGardient;
    }
  }

  &.third,
  &.seventh {
    .main-content {
      background: $thirdTypeGardient;
    }
  }

  &.fourth,
  &.eighth {
    .main-content {
      background: $fourthTypeGardient;
    }
  }

  &.fifth,
  &.ninth {
    .main-content {
      background: $fifthTypeGardient;
    }
  }

  &.regular {
    margin-bottom: 50px;

    @include screen('mobile_annual_md') {
      margin-bottom: 40px;
    }

    .main-content {
      position: relative;
      padding: 6px 8px 6px 210px;
      background-color: #fff;
      @include screen('mobile_annual_xl') {
        padding: 10px;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
        height: 6px;
        background: $regularItemBorderTopGradient;
        @include screen('mobile_annual_xl') {
          top: -4px;
          height: 4px;
        }
      }
      .general-info {
        display: flex;
        align-items: flex-start;
        @include screen('mobile_annual_xl') {
          flex-direction: row;
          padding-bottom: 73px;
        }
        .image {
          margin-left: 30px;
          @include screen('mobile_annual_xl') {
            width: auto;
            margin-left: 8px;
            padding-bottom: 0;
            align-items: flex-start;
          }
          img {
            display: block;
            width: 175px;
            height: 175px;
            object-fit: cover;
            @include screen('mobile_annual_xl') {
              position: relative;
              width: 155px;
              height: 155px;
            }
          }
        }
        .info {
          padding-bottom: 10px;
          @include screen('mobile_annual_xl') {
            padding-top: 0;
          }
          .info-wrapper {
            padding-top: 15px;
            @include screen('mobile_annual_xl') {
              padding: 0;
            }
            .song-name {
              padding-bottom: 1px;
              font-size: 30px;
              line-height: 30px;
              color: #000;
              @include screen('mobile_annual_xl') {
                padding-left: 0px;
                padding-bottom: 4px;
                font-size: 24px;
                line-height: 24px;
              }
            }
            .artist-name {
              padding-bottom: 6px;
              font-size: 20px;
              line-height: 20px;
              color: #000;
              @include screen('mobile_annual_xl') {
                padding-bottom: 10px;
                font-size: 21px;
                line-height: 22px;
                font-family: $main-font_medium;
              }
            }
            .release-date {
              font-size: 16px;
              line-height: 18.13px;
              color: #000;
              @include screen('mobile_annual_xl') {
                font-size: 18px;
                line-height: 19px;
              }
            }
          }
          .fist-places-number {
            display: none;
          }
          .open-btn {
            padding: 0 23px;
            font-size: 18px;
            color: #fff;
            background-color: #000;

            @include screen('mobile_annual_xl') {
              display: none;
            }

            .cross {
              width: 18px;
              height: 18px;
              &:before,
              &::after {
                width: 12.38px;
                height: 3px;
                background-color: #fff;
              }
            }
          }
        }
      }

      .number {
        top: -5px;
        left: 30px;
        font-size: 140px;
        line-height: 160px;
        color: #000;

        @include screen('mobile_annual_xl') {
          display: block;
          top: auto;
          bottom: 0;
          left: 10px;
          font-size: 70px;
          line-height: 70px;
        }
      }

      .open-btn-regular-mobile {
        @include screen('mobile_annual_xl') {
          position: absolute;
          right: 10px;
          bottom: 14px;
          display: flex;
          width: fit-content;
          height: 36px;
          align-items: center;
          padding: 0 22px;
          font-size: 18px;
          font-family: serif;
          color: #fff;
          font-family: $main-font_bold;
          background-color: #000;

          &.active {
            .cross {
              transform: rotate(45deg);
            }
          }

          .cross {
            position: relative;
            width: 18px;
            height: 18px;
            margin-right: 8px;

            &:before,
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              top: 50%;
              width: 12.38px;
              height: 3px;
              background-color: #fff;
            }
            &:before {
              transform: translate(-50%, -50%);
            }
            &:after {
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }
        }
      }
    }
  }

  .main-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 11px 10px 11px 250px;

    @include screen('mobile_annual_xl') {
      padding: 15px;
    }

    .general-info {
      display: flex;
      align-items: flex-start;

      @include screen('mobile_annual_xl') {
        width: 100%;
        flex-direction: column;
      }

      .image {
        margin-left: 20px;

        @include screen('mobile_annual_xl') {
          position: relative;
          width: 100%;
          padding-bottom: 100%;
          margin-left: 0;
        }
        img {
          display: block;
          width: 192px;
          height: 192px;
          object-fit: cover;

          @include screen('mobile_annual_xl') {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          @include screen('mobile_annual_md') {
            height: 100%;
          }
          @include screen('mobile_annual_sm') {
            height: 100%;
          }
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        @include screen('mobile_annual_xl') {
          position: relative;
          width: 100%;
          height: auto;
          padding-top: 27px;
        }
        @include screen('mobile_annual_md') {
          padding-top: 17px;
        }
        .info-wrapper {
          padding-top: 7px;
          @include screen('mobile_annual_xl') {
            padding-top: 0;
            padding-bottom: 55px;
          }
          .song-name {
            padding-bottom: 5px;
            font-size: 38px;
            line-height: 30.5px;
            font-family: $main-font_bold;
            color: #fff;
            @include screen('mobile_annual_xl') {
              padding-left: 150px;
              padding-bottom: 2px;
              font-size: 34px;
              line-height: 30.5px;
            }
            @include screen('mobile_annual_md') {
              font-size: 30px;
            }
          }
          .artist-name {
            padding-bottom: 8px;
            font-size: 21px;
            line-height: 23.49px;
            font-family: $main-font_bold;
            color: #fff;
            @include screen('mobile_annual_xl') {
              font-size: 21px;
              line-height: 23.49px;
            }
          }
          .release-date {
            font-size: 20px;
            line-height: 23.49px;
            font-family: $main-font;
            color: #fff;
            @include screen('mobile_annual_xl') {
              font-size: 16px;
              line-height: 23.49px;
            }
          }
        }

        .fist-places-number {
          display: none;
          @include screen('mobile_annual_xl') {
            display: block;
            position: absolute;
            left: 18px;
            top: -87px;
            font-size: 140px;
            line-height: 159.9px;
            color: #fff;
            font-family: $secondary-font;
          }
          @include screen('mobile_annual_md') {
            left: 3px;
            top: -75px;
          }
        }

        .open-btn {
          display: flex;
          width: fit-content;
          height: 36px;
          align-items: center;
          padding: 0 6px;
          font-size: 22px;
          font-family: serif;
          color: #000;
          font-family: $main-font_bold;
          background-color: #fff;

          &.active {
            .cross {
              transform: rotate(45deg);
            }
          }

          .cross {
            position: relative;
            width: 22px;
            height: 22px;
            margin-right: 6px;

            &:before,
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              top: 50%;
              width: 15.2px;
              height: 4px;
              background-color: #000;
            }
            &:before {
              transform: translate(-50%, -50%);
            }
            &:after {
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }
        }
      }
    }

    .number {
      position: absolute;
      top: -47px;
      left: 34px;
      font-size: 211.07px;
      line-height: 241.17px;
      color: #fff;
      font-family: $secondary-font;
      @include screen('mobile_annual_xl') {
        display: none;
      }
    }

    .open-btn-regular-mobile {
      display: none;
    }
  }

  .player {
    margin-top: 2px;
    padding: 18.67px 17px;
    background-color: #fff;
    @include screen('mobile_annual_xl') {
      padding: 9px 10px 10px 10px;
    }
    .player-embed {
      height: 190px;
      @include screen('mobile_annual_xl') {
        height: 90px;
      }

      iframe {
        display: block;
        border: 0;
      }
    }

    .player-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -11px;

      @include screen('mobile_annual_xl') {
        margin-top: 5px;
      }

      .platforms {
        display: flex;
        align-items: center;
        padding-right: 3px;

        @include screen('mobile_annual_xl') {
          padding-right: 0px;
        }

        button {
          margin-right: 17.15px;

          @include screen('mobile_annual_xl') {
            margin-right: 12px;
          }

          &:first-child {
            margin-right: 0;
          }
          img {
            display: block;
            height: 31px;

            @include screen('mobile_annual_xl') {
              height: 24px;
            }
          }
        }
      }

      .actions {
        padding-left: 6px;

        @include screen('mobile_annual_xl') {
          padding-left: 0px;
        }
        button {
          display: flex;
          align-items: center;
          height: 41.65px;
          padding: 0 17.62px 0 17px;
          font-size: 22px;
          font-family: $main-font_bold;
          border: 1.23px solid $black-color;

          @include screen('mobile_annual_xl') {
            height: 24px;
            padding: 0 6px;
            font-size: 14px;
            border: 1px solid $black-color;
          }
        }
      }
    }
  }
}

@keyframes firstPlace {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -47360px;
  }
}
