@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.footer {
  width: 100%;
  max-width: 1440px;
  padding: 60px 115.5px 75px;
  margin: 0 auto;
  @include screen('desktop_sm') {
    max-width: 900px;
    width: 100%;
    padding: 60px 0 75px;
  }

  @include screen('tablet') {
    max-width: inherit;
    width: 100%;
    padding: 60px 50px 75px;
  }

  @include screen('mobile_annual_xl') {
    max-width: inherit;
    padding: 27.5px 16px 27.5px;
  }

  &.on-category {
    max-width: inherit;
    background: linear-gradient(
      72.27deg,
      #7f24d1 -2.02%,
      #7b27d2 14.96%,
      #7031d4 30.24%,
      #5c41d8 45.53%,
      #4158dd 60.81%,
      #1f75e3 75.24%,
      #157de5 78.64%
    );

    @include screen('mobile_annual_xl') {
      max-width: inherit;
    }
    .footer-wrapper {
      width: 100%;
      max-width: 1209px;
      margin: 0 auto;
      padding: 0 100px;
      @include screen('mobile_annual_xl') {
        max-width: inherit;
        padding: 0 16px 0;
      }
    }
  }

  .footer-wrapper {
    width: 100%;
  }

  .text {
    padding-bottom: 42px;
    font-size: 22px;
    line-height: 24px;
    color: #fff;
    font-family: $main-font_medium;
    text-align: center;

    @include screen('mobile_annual_xl') {
      padding-bottom: 40px;
      font-size: 18px;
      line-height: 20px;
      font-family: $main-font_demibold;
    }
  }

  .logo {
    width: 142px;
    margin: 0 auto 42px;

    @include screen('mobile_annual_xl') {
      width: 72px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
