@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.item {
  position: relative;

  &.first-place {
    .main-content {
      height: 181px;
      background-color: $black-color;
      color: white;

      @include screen('mobile_lg') {
        height: 160px;
      }

      .position {
        position: relative;
        width: 148px;

        @include screen('mobile_lg') {
          width: 54px;
          align-items: center;
        }

        .number {
          width: 40%;
          font-size: 88px;
          line-height: 92px;
          margin-right: 35px;

          @include screen('mobile_lg') {
            font-size: 80px;
            margin-right: 0;

            span {
              transform: translate(-4px, 20px);
            }
          }
        }

        .arrow {
          width: 50%;
          margin-left: 35px;

          @include screen('mobile_lg') {
            margin-left: 0;
            transform: translateY(10px);
          }

          &::before {
            top: 45%;
            border-width: 12px;
            border-right-width: 20px;
          }
        }
      }

      .info {
        .cover {
          width: 170px;
          margin-left: 16px;

          @include screen('mobile_lg') {
            margin-left: 6px;
            width: 140px;
            min-width: 140px;
          }
        }

        .texts {
          margin-left: 30px;

          @include screen('mobile_xl') {
            margin-left: 0px;
          }

          .artist-and-title {
            @include screen('mobile_xl') {
              display: flex;
              width: 100%;
              height: 100%;
              flex-direction: column;
              // align-items: center;
              justify-content: center;
              margin-left: 0;
            }
            .title {
              font-size: 27.73px;
              font-weight: 400;
              line-height: 23.46px;
              margin-bottom: 4px;
              -webkit-line-clamp: 3;

              @include screen('mobile_lg') {
                font-size: 20px;
                line-height: 16px;
              }
            }

            .artist {
              font-size: 21.33px;
              font-weight: 400;
              line-height: 21.33px;

              @include screen('mobile_lg') {
                font-family: $main-font;
                font-size: 16px;
                line-height: 16px;
              }
            }
          }

          .position-stats {
            gap: 22.35px;

            @include screen('mobile_lg') {
              display: none;
            }

            &-title {
              font-family: $main-font_bold;
              font-size: 20px;
              line-height: 29.4px;
              font-weight: 400;
              color: #f1f1f1e5;
            }

            &-value {
              color: #f1f1f1e5;
            }

            .all-time-record {
              display: none;
            }
          }
        }

        .cross-icon-wrap {
          position: relative;
          width: 72px + 36px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include screen('tablet') {
            margin-left: 0;
          }

          @include screen('mobile_xl') {
            width: 22px + 14px;
          }

          .cross-icon {
            position: relative;
            width: 32px;
            height: 32px;

            @include screen('mobile_xl') {
              width: 20px;
              height: 20px;
            }

            &::before,
            &::after {
              height: 8px;
              background-color: white;

              @include screen('mobile_xl') {
                height: 5px;
              }
            }
          }
        }
      }
    }

    .player {
      color: white;
      background-color: $black-color;
      border-top: 1px solid white;

      &-header {
        .position-stats {
          & > div {
            &:not(:last-child) {
              &::before {
                background-color: rgba(white, 0.5);
              }
            }
          }
        }
      }

      &-footer {
        @include screen('mobile_lg') {
          margin-bottom: 4px;
        }

        .actions {
          button {
            border-color: white;
          }
        }
      }
    }
  }

  &.active {
    .cross-icon {
      transform: rotate(45deg);
    }
  }

  &.up {
    //
  }

  &.down {
    .main-content {
      .position {
        .arrow {
          &::before {
            border-right-color: #ec1c28;
            transform: translate(-50%, -20%) rotate(270deg);
          }
        }
      }
    }
  }

  &.none {
    .main-content {
      .position {
        .arrow {
          &::before {
            border-right-color: #abacb4;
            transform: translate(-75%, -50%) rotate(0deg);
          }
        }
      }
    }

    &.first-place {
      .main-content {
        .position {
          .arrow {
            &::before {
              top: 50%;
              border-right-color: #abacb4;
              transform: translate(-75%, -50%) rotate(0deg);

              @include screen('mobile_lg') {
                transform: translate(-75%, -75%) rotate(0deg);
              }
            }
          }
        }
      }
    }
  }

  &.new-item,
  &.returned-to-chart {
    .main-content {
      .position {
        .arrow {
          &::before {
            display: none;
          }

          span {
            display: block;
          }
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.main-content {
  position: relative;
  width: 100%;
  height: 122px;
  display: flex;
  background-color: white;
  color: $black-color;
  cursor: pointer;
  overflow: hidden;

  @include screen('mobile_xl') {
    height: 120px;
  }
}

.position {
  display: flex;

  @include screen('mobile_lg') {
    flex-direction: column;
    overflow: hidden;
    min-width: 54px;
  }

  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 109px;
    font-family: $secondary-font;
    font-size: 40px;
    line-height: 46px;
    color: white;
    background-color: $black-color;

    @include screen('mobile_lg') {
      width: 54px;
      height: 50%;
      font-size: 26px;
      line-height: 30px;

      span {
        position: relative;
        transform: translateY(10px);
      }

      img {
        position: relative;
        transform: translateY(10px);
        width: 25px;
      }
    }
  }

  .arrow {
    position: relative;
    width: 69px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen('mobile_lg') {
      color: white;
    }

    span {
      font-family: $main-font_black;
      font-size: 18px;
      line-height: 14px;
      display: none;

      @include screen('mobile_lg') {
        transform: translateY(-7px);
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0px;
      height: 0px;
      border: 10px solid transparent;
      border-right-width: 17px;
      border-right-color: #00bf61;
      transform: translate(-50%, -50%) rotate(90deg);

      @include screen('mobile_lg') {
        top: 40%;
        transform: translate(-50%, calc(-50% - 8px)) rotate(90deg);
      }
    }

    @include screen('mobile_lg') {
      width: 54px;
      height: 50%;
      background-color: $black-color;
    }
  }
}

.info {
  display: flex;
  flex-grow: 1;
  align-items: center;

  .cover {
    position: relative;
    margin-left: 14px;
    width: 122px;
    min-width: 122px;
    height: 100%;
    background-color: #eee;
    background-size: cover;
    background-position: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    @include screen('mobile_lg') {
      width: 120px;
      min-width: 120px;
      margin-left: 12px;
    }
  }

  .texts {
    position: relative;
    margin-left: 4px;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screen('mobile_lg') {
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 19px 0 12px;
    }

    @include screen('mobile_sm') {
      padding: 12px 0 12px;
    }

    .artist-and-title {
      flex-grow: 1;
      margin-left: 20px;
      max-width: 257px;

      @include screen('mobile_xl') {
        margin-left: 30px;
        max-width: none;
      }

      @include screen('mobile_lg') {
        margin-left: 30px;
        max-width: none;
      }

      h3,
      h4 {
        margin: 0;
      }

      .title {
        font-family: $main-font_bold;
        font-size: 23.49px;
        font-weight: 400;
        line-height: 23.49px;
        margin-bottom: 8px;

        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;

        @include screen('mobile_lg') {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 6px;
        }

        @include screen('mobile_sm') {
          margin-bottom: 3px;
        }
      }

      .artist {
        font-size: 19.22px;
        font-weight: 400;
        line-height: 18.5px;

        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;

        @include screen('mobile_lg') {
          font-size: 16px;
          line-height: 17px;
          font-weight: 400;
        }

        @include screen('mobile_sm') {
          margin-bottom: 3px;
        }
      }
    }

    .position-stats {
      display: flex;
      gap: 14px;
      font-family: $main-font_bold;
      color: #6a6a6a;
      text-align: center;

      @include screen('tablet') {
        display: none;
      }

      .prev-week,
      .all-time-record {
        @include screen('mobile_lg') {
          display: flex;
          gap: 5px;
        }
      }

      .prev-week {
        @include screen('mobile_lg') {
          position: relative;
          margin-left: 5px;
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            height: 12px;
            left: -10px;
            top: 0;
            background-color: #6a6a6a;
          }
        }

        @include screen('mobile_sm') {
          &::after {
            display: none;
          }
        }
      }

      .position-stats-title {
        font-family: $main-font_medium;
        font-size: 18px;
        line-height: 26.46px;
        white-space: nowrap;

        @include screen('mobile_lg') {
          font-size: 14px;
          line-height: 12px;

          &::after {
            display: inline;
            content: ':';
          }
        }
      }

      .position-stats-value {
        font-size: 22px;
        line-height: 32px;

        @include screen('mobile_lg') {
          font-size: 14px;
          line-height: 12px;
          white-space: nowrap;
        }
      }
    }
  }

  .cross-icon-wrap {
    position: relative;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include screen('tablet') {
      margin-left: 4px;
    }

    @include screen('mobile_xl') {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 28px;
    }

    .cross-icon {
      position: relative;
      width: 23.49px;
      height: 23.49px;

      @include screen('mobile_xl') {
        width: 19.25px;
        height: 19.25px;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: $black-color;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include screen('mobile_xl') {
          height: 5px;
        }
      }

      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}

.player {
  position: relative;
  border-top: 1px solid $black-color;
  padding: 13px 15px 23px;
  background-color: white;

  @include screen('mobile_lg') {
    padding: 14px 11px;
  }

  &-header {
    .position-stats {
      display: none;
      width: 100%;
      margin: 0 auto 20px;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      @include screen('tablet') {
        display: flex;
      }

      & > div {
        position: relative;
        display: flex;
        justify-content: center;
        flex-grow: 1;

        &:not(:last-child) {
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 2px;
            height: 75%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(black, 0.5);
          }
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
      }

      &-title {
        @include screen('tablet') {
          font-size: 18px;
          line-height: 22px;
        }

        @include screen('mobile_sm') {
          font-size: 15px;
          line-height: 22px;
        }
      }

      &-value {
        font-family: $main-font_bold;

        @include screen('tablet') {
          font-size: 22px;
          line-height: 25px;
        }

        @include screen('mobile_sm') {
          font-size: 17px;
          line-height: 25px;
        }
      }
    }
  }

  &-embed {
    position: relative;
    height: 152px;
    margin-bottom: 14px;

    @include screen('tablet') {
      margin-bottom: 22px;
    }

    @include screen('mobile_xl') {
      margin-bottom: 16px;
    }

    @include screen('mobile_lg') {
      height: 80px;
    }

    iframe {
      display: block;
      border: 0;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .platforms {
      display: flex;
      align-items: center;
      gap: 14px;

      @include screen('mobile_xl') {
        gap: 12px;
      }

      button {
        width: 31px;
        height: 31px;
        transition: opacity;
        transition-duration: 0.35s;

        @include screen('tablet') {
          width: 31px;
          height: 31px;
        }

        @include screen('mobile_xl') {
          width: 24px;
          height: 24px;
        }

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          opacity: 0.8;
        }

        &.platform-YT {
          width: 44px;
          height: 31px;

          @include screen('mobile_xl') {
            width: 35px;
            height: 24px;
          }
        }

        &.platform-apple-music {
          width: 33px;
          height: 31px;

          @include screen('mobile_xl') {
            width: 26px;
            height: 24px;

            img {
              width: 26px;
              height: 24px;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      gap: 14px;

      button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        padding: 0 10px;
        border: 1px solid $black-color;
        font-family: $main-font_bold;
        font-size: 16px;
        line-height: 24px;
        transition: opacity;
        transition-duration: 0.35s;

        &:hover {
          opacity: 0.8;
        }

        @include screen('mobile_xl') {
          font-size: 14px;
          line-height: 21px;
          height: 24px;
        }
      }
    }
  }
}
