@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.top-section-wrapper {
  position: relative;
  padding-bottom: 60px;
  z-index: 101;

  .intro {
    position: relative;
    width: 100%;
    height: 400px;

    @include screen('mobile_annual_xl') {
      height: 400px;
    }

    @include screen('mobile_annual_md') {
      height: 340px;
    }

    @include screen('mobile_annual_sm') {
      height: 340px;
    }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    .logo-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 1055px;
      padding: 0 90px;

      @include screen('mobile_annual_xl') {
        max-width: inherit;
        padding: 0 23.5px;
      }

      @include screen('mobile_annual_md') {
        max-width: inherit;
        padding: 0 23.5px;
      }

      @include screen('mobile_annual_sm') {
        max-width: inherit;
        padding: 0 23.5px;
      }

      img {
        display: block;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  #gasket {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
  }

  .top-section-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: linear-gradient(
      86.08deg,
      #00bf61 3.76%,
      #524bfd 24.61%,
      #5d3dc2 37.78%,
      #ec1c28 76.19%,
      #ff8701 89.36%,
      #fe8f04 91.55%,
      #fba60f 94.85%,
      #f8cc20 99.24%,
      #f7dc27 100.33%
    );
    @include screen('mobile_annual_xl') {
      height: 60px;
    }

    &.fixed {
      position: fixed;
      bottom: auto;
      top: 0;
    }

    .navigation-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      max-width: 1512px;
      padding: 0 40px;
      margin: 0 auto;

      @include screen('mobile_annual_xl') {
        max-width: inherit;
        padding: 0 20px 0 0;
        justify-content: flex-start;
        overflow-y: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .charts-select {
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 144px;
        flex-shrink: 0;
        margin-left: 80px;

        @include screen('mobile_annual_xl') {
          width: auto;
          max-width: inherit;
          margin-left: 30px;
        }

        .current-chart {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 24px;
          line-height: 35.27px;
          font-family: $main-font_bold;
          color: #fff;
          cursor: pointer;

          @include screen('mobile_annual_xl') {
            font-size: 21px;
            line-height: 30.87px;
          }
          span {
            padding-left: 5px;
            @include screen('mobile_annual_xl') {
              padding-left: 0;
            }
          }

          img {
            @include screen('mobile_annual_xl') {
              display: block;
              width: 13.75px;
            }
          }
        }
        .charts-dropdown {
          position: absolute;
          width: auto;
          top: 100%;
          padding: 8px 16px;
          background-color: rgba(0, 0, 0, 0.8);
          opacity: 0;
          visibility: hidden;
          transition: all ease 0.3s;

          @include screen('mobile_annual_xl') {
            display: none;
          }

          &.open {
            opacity: 1;
            visibility: visible;
          }

          .chart {
            padding: 8px 0;
            font-size: 21px;
            line-height: 30.87px;
            color: #fff;
            font-family: $main-font_medium;
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
    }

    .charts-dropdown-mobile {
      display: none;
      @include screen('mobile_annual_xl') {
        display: block;
        position: absolute;
        width: auto;
        top: 100%;
        padding: 8px 25px;
        background-color: rgba(23, 23, 23, 1);
        opacity: 0;
        visibility: hidden;
        transition: all ease 0.3s;

        &.open {
          opacity: 1;
          visibility: visible;
        }

        .chart {
          padding: 8px 0;
          font-size: 21px;
          line-height: 30.87px;
          color: #fff;
          font-family: $main-font_medium;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }
}
