@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.side-banner {
  pointer-events: visiblePainted;
  position: absolute;
  display: none;
  z-index: 100;
  right: 0;
  top: 57px;
  height: 647px;

  @include screen('desktop_sm') {
    display: none !important;
  }

  &.fixed {
    position: fixed;
    top: 117px;
  }

  .banner-container {
    padding: 0 12px 12px;
    background-color: #171717;
    position: absolute;
    right: 0;
    top: 0;

    .title {
      display: flex;
      width: 100%;
      min-height: 35px;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 26.46px;
      color: white;
    }

    .banner {
      background-color: transparent;
    }
  }

  &.rendered {
    display: block;
  }
}
