@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  position: relative;
  z-index: 5;
  display: flex;
}

.content {
  position: relative;
  overflow: hidden;
  width: 100%;

  &.main {
    background: linear-gradient(
      83.14deg,
      #ff8900 -1.98%,
      #fe8203 11.05%,
      #fa6e0a 30.15%,
      #f54d16 53.6%,
      #ed2027 79.65%,
      #ec1c28 81.39%
    );
  }

  &.international {
    background: linear-gradient(
      72.27deg,
      #7f24d1 -2.02%,
      #7b27d2 14.96%,
      #7031d4 30.24%,
      #5c41d8 45.53%,
      #4158dd 60.81%,
      #1f75e3 75.24%,
      #157de5 78.64%
    );
  }

  &.israel {
    background: linear-gradient(72.27deg, #1c61f5 -3.72%, #00d17a 83.13%);
  }

  &.new-songs {
    background: linear-gradient(261.89deg, #ecd402 0.25%, #0bc878 92.36%);

    .rest-items-wrap {
      @include screen('mobile_xl') {
        padding-top: 18px;
      }

      @include screen('mobile_sm') {
        padding-top: 16px;
      }
    }
  }
}

.first-place-item-wrap {
  position: relative;
  padding: 36px 0;

  @media screen and (min-width: 1024px) {
    background-color: white;
  }

  @include screen('mobile_lg') {
    padding: 16px 0;
    background-color: transparent;
  }
}

.rest-items-wrap {
  position: relative;
  padding: 36px 0;

  @include screen('mobile_xl') {
    padding-top: 0;
  }

  .item {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.empty {
    padding: 75px 0;

    @include screen('tablet') {
      padding: 60px 0;
    }

    @include screen('mobile_xl') {
      padding: 36px 0;
    }
  }
}

.recomended-items-wrap {
  position: relative;
  margin-bottom: 25px;

  .recomended-items {
    background-color: white;
    padding: 21px 16px 0;

    @include screen('mobile_xl') {
      padding: 6px 6px 0;
    }
  }

  .recomended-items-header {
    padding: 18.5px;
    background-color: #171717;
    font-family: $motaleh-font;
    font-size: 34px;
    line-height: 21.28px;
    text-align: center;
    color: white;
    margin-bottom: 27px;

    @include screen('mobile_xl') {
      font-size: 29px;
      line-height: 21.28px;
      padding: 18.5px;
      margin-bottom: 6px;
    }
  }

  .recomended-items-row {
    .items-wrap {
      position: relative;
      width: 100%;
      display: flex;
      gap: 16px;

      @include screen('mobile_xl') {
        gap: 6px;
      }
    }
  }
}

.content-wrap {
  max-width: $content-width-desktop;
  margin: 0 auto;
}

.no-new-songs {
  background-color: white;
  padding: 25px;
  font-family: $main-font_bold;
  font-weight: 700;
  font-size: 30px;
  line-height: 44.09px;
  text-align: center;

  @include screen('mobile_xl') {
    padding: 28px 25px;
    font-size: 26px;
    line-height: 38px;
  }
}

.seo-text,
.sponsor-text {
  padding: 36px 0 36px;
  color: white;
  font-size: 22px;
  line-height: 24px;
  text-align: center;

  @include screen('tablet') {
    padding: 0 0 36px;
    font-size: 18px;
    line-height: 20px;
  }

  p {
    padding: 0 10px;

    @include screen('tablet') {
      padding: 0 16px;
    }
  }

  a {
    color: inherit;
    margin-right: 10px;
  }
}

.footer-logos {
  padding: 20px 0 72px;

  @include screen('tablet') {
    padding: 0;
    position: absolute;
    z-index: 10;
    right: 20px;
    left: 20px;
    bottom: 90px;
  }

  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px;

    .coke-logo {
      img {
        width: 80px;
      }
    }

    .mako-logo {
      img {
        width: 240px;
      }
    }

    @include screen('tablet') {
      justify-content: flex-start;

      .mako-logo {
        img {
          width: 120px;
        }
      }

      .coke-logo {
        margin-right: -10px;
        img {
          width: 40px;
        }
      }
    }
  }
}

.sponsor-text {
  padding: 36px 0 0px;

  @include screen('tablet') {
    padding: 0 0 36px;
  }
}

.save-chart-msg {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black-color;
  color: white;
  padding: 21px;
  text-align: center;
  font-family: $main-font_bold;
  font-size: 24px;
  line-height: 18px;
  transform: translateY(100%);
  transition-duration: 0.35s;

  @include screen('tablet') {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    line-height: 28px;
    padding: 13px;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .close-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);

    @include screen('tablet') {
      position: relative;
      top: auto;
      right: auto;
      display: block;
      width: 45px;
      height: 5px;
      border-radius: 5px;
      background-color: white;
      transform: none;
      margin-bottom: 11px;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 2px;
      background-color: white;
      transform: translate(-50%, -50%);

      @include screen('tablet') {
        display: none;
      }
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &.open {
    transform: translateY(0);
  }

  &.with-outbrain {
    position: absolute;
    z-index: 1;
    bottom: 0;
  }
}

.mako-link {
  position: absolute;
  left: 240px;
  bottom: 18px;
  transition: transform 0.35s;

  @include screen('tablet') {
    left: 50%;
    bottom: -7px;
    transform: translate(-50%, 10px);
  }

  a {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 1.3;
    color: white;
  }

  &.with-translate {
    transform: translate(0, -60px);

    @include screen('tablet') {
      transform: translate(-50%, 10px);
    }
  }

  &.without-SEO-text {
    @include screen('tablet') {
      margin-top: -40px;
      // transform: translate(0, -40px) !important;
    }
  }
}

.rabbi-credits {
  position: fixed;
  left: 50px;
  bottom: 0;
  opacity: 0.8;
  transform: translate(0, 0);
  transition: transform 0.35s;

  @include screen('tablet') {
    opacity: 1;
    left: 0;
    position: relative;
  }

  &-content {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 1.3;
    padding: 20px 40px;
    direction: ltr;
    color: white;

    @include screen('tablet') {
      text-align: left;
      font-size: 14px;
      padding: 0 20px 100px;
    }

    a {
      color: inherit;
      letter-spacing: 0.2px;

      @include screen('tablet') {
        font-weight: bold;
      }
    }
  }

  &.with-translate {
    transform: translate(0, -60px);

    @include screen('tablet') {
      transform: translate(0, 0);
    }
  }

  &.without-SEO-text {
    @include screen('tablet') {
      margin-top: -40px;
      // transform: translate(0, -40px) !important;
    }
  }

  &.with-outbrain {
    position: absolute;

    @include screen('tablet') {
      position: relative;
    }
  }
}

.side-banner {
  width: 0;
  transition: padding-bottom 0.35s;

  &.with-save-chart-msg {
    padding-bottom: 60px;
  }
}
