@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.songs {
  // width: 100%;
  max-width: 1435px;
  margin: 0 auto;
  padding-top: 156px;
  padding-left: 242px;
  padding-right: 243px;
  // padding-bottom: 104px;
  box-sizing: content-box;

  @include screen('desktop_sm') {
    padding-left: 0;
    padding-right: 0;
    max-width: 1027px;
  }

  @include screen('desktop_annual_lg') {
    max-width: 900px;
    padding-left: 0;
    padding-right: 0;
  }

  @include screen('mobile_annual_xl') {
    max-width: inherit;
    padding-top: 40px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 45px;
  }

  .embed-wrapper {
    width: 100%;
    margin-bottom: 112px;

    @include screen('mobile_annual_xl') {
      margin-bottom: 40px;
    }

    .embed {
      position: relative;
      margin: 0 115.5px;
      // background-color: #fff;
      aspect-ratio: 16 / 9;
      @include screen('desktop_sm') {
        margin: 0 63.5px;
      }
      @include screen('desktop_annual_lg') {
        margin: 0;
      }
      @include screen('mobile_annual_xl') {
        margin: 0;
      }

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }

  .item-wrapper {
    &.with-banner {
      margin-bottom: 55px;

      @include screen('mobile_annual_xl') {
        margin-bottom: 45px;
      }
    }

    .banner-wrapper {
      display: flex;
      justify-content: center;
    }
  }
  .annual-content-banner {
    max-width: 994px;
    margin: 0 auto;
  }
}
