// Fonts:
$main-font: 'Ploni-Regular';
$main-font_black: 'Ploni-Black';
$main-font_bold: 'Ploni-Bold';
$main-font_demibold: 'Ploni-Demibold';
$main-font_light: 'Ploni-Light';
$main-font_medium: 'Ploni-Medium';
$main-font_ultrabold: 'Ploni-Ultrabold';
$main-font_ultralight: 'Ploni-Ultralight';
$secondary-font: 'Short-Xurkit-Tilt';
$almoni-font: 'Almoni-DL-AAA-Bold';
$motaleh-font: 'Motaleh-Bold-Fm';

// Media queries:
$mq-mobile_sm: '(max-width: 320px)';
$mq-mobile_md: '(max-width: 390px)';
$mq-mobile_lg: '(max-width: 480px)';
$mq-mobile_xl: '(max-width: 1023px)';
$mq-tablet: '(max-width: 1024px)';
$mq-desktop_sm: '(max-width: 1423px)';
$mq-desktop_md: '(max-width: 1919px)';
$mq-desktop_lg: '(min-width: 1920px)';

//Media queries (annual chart):
$mq-mobile_annual_xl: '(max-width: 799px)';
$mq-mobile_annual_md: '(max-width: 390px)';
$mq-mobile_annual_sm: '(max-width: 320px)';
$mq-desktop_annual_lg: '(max-width: 1027px)';

// sizes:
$content-width-desktop: 994px;

// colors:
$black-color: #171717;
$black-color_100: #272727;
$black-color_200: #373737;

//annual charts items gradients
$firstItemGardient: linear-gradient(
  81.64deg,
  #00bf61 1.52%,
  #524bfd 14%,
  #5d3dc2 22.07%,
  #81359b 33.12%,
  #a52c74 40.72%,
  #ec1c28 52.27%,
  #ff8701 72.67%,
  #fe8f04 81%,
  #fba60f 87.27%,
  #f8cc20 94.57%,
  #f7dc27 102.33%
);
$secondTypeGardient: linear-gradient(85.68deg, #c8244f -0.8%, #f75d10 86.95%);
$thirdTypeGardient: linear-gradient(85.68deg, #633183 -0.8%, #953085 86.95%);
$fourthTypeGardient: linear-gradient(85.81deg, #3d69d5 -0.81%, #2a128f 100.02%);
$fifthTypeGardient: linear-gradient(261.38deg, #2272a3 -3.29%, #01be63 91.36%);

$regularItemBorderTopGradient: linear-gradient(
  81.64deg,
  #00bf61 1.52%,
  #524bfd 14%,
  #5d3dc2 22.07%,
  #81359b 33.12%,
  #a52c74 40.72%,
  #ec1c28 52.27%,
  #ff8701 72.67%,
  #fe8f04 81%,
  #fba60f 87.27%,
  #f8cc20 94.57%,
  #f7dc27 102.33%
);
