@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.header-wrapper {
  position: relative;
  height: 71px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $black-color;
  box-sizing: border-box;
  z-index: 9999;

  @include screen('mobile_annual_xl') {
    height: 52px;
    justify-content: center;
  }

  .navgation {
    margin-right: 40px;
    margin-top: -8px;
    @include screen('mobile_annual_xl') {
      position: absolute;
      right: 0;
      top: 100%;
      display: flex;
      flex-direction: column;
      margin-right: 0;
      margin-top: 0;
      padding: 20px 11px;
      background-color: $black-color;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.3s;
    }
    &.open {
      @include screen('mobile_annual_xl') {
        opacity: 1;
        visibility: visible;
      }
    }
    .nav-link {
      margin-right: 60px;
      text-decoration: none;
      @include screen('mobile_annual_xl') {
        margin-right: 0;
        padding-top: 20px;
      }
      &:first-child {
        margin-right: 0;
        padding-top: 0;
      }
      span {
        display: inline-block;
        position: relative;
        font-size: 21px;
        line-height: 30.87px;
        color: #fff;
        font-family: $main-font_medium;
        &:after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: transparent;
          transition: all ease 0.3s;
        }
        &.active {
          font-family: $main-font_bold;
          &:after {
            background-color: #fff;
          }
        }
        &:hover {
          &:after {
            background-color: #fff;
          }
        }
      }
    }
  }

  .mako-logo {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0%, -50%);
    width: 94px;
    @include screen('mobile_annual_xl') {
      display: none;
    }
  }

  .hitlist-logo {
    width: 141px;
    margin-top: -15px;

    @include screen('mobile_annual_xl') {
      width: 97px;
      margin-top: 0;
    }
  }

  .menu-btns {
    display: none;
    @include screen('mobile_annual_xl') {
      display: block;
      position: absolute;
      right: 16px;
      top: 12px;
    }

    .close-menu-btn,
    .menu-btn {
      width: 28px;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  img {
    display: block;
    width: 100%;
  }
}
