@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  &.with-select {
    padding-left: 74px;
  }

  @include screen('mobile_annual_xl') {
    overflow-y: auto;
    padding-left: 0;
  }

  .navigation-links {
    display: flex;
    align-items: center;
    height: 100%;
    .navigation-item {
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 32px;

      @include screen('mobile_annual_xl') {
        white-space: nowrap;
      }

      &:first-child {
        padding-right: 0;
      }

      &:last-child {
        @include screen('mobile_annual_xl') {
          padding-left: 20px;
        }
      }

      a {
        text-decoration: none;

        span {
          display: inline-block;
          position: relative;
          font-size: 21px;
          line-height: 30.87px;
          color: #fff;
          font-family: $main-font_medium;
          white-space: nowrap;
          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: transparent;
            transition: all ease 0.3s;
          }
          &.active {
            font-family: $main-font_bold;
            &:after {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .fake-navigation {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    left: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    .navigation-item {
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 32px;

      &:first-child {
        padding-right: 0;
      }

      a {
        text-decoration: none;

        span {
          display: inline-block;
          position: relative;
          font-size: 21px;
          line-height: 30.87px;
          color: #fff;
          font-family: $main-font_medium;
          white-space: nowrap;
        }
      }
    }
  }

  .categories-select {
    position: absolute;
    height: 100%;
    left: 0;

    @include screen('mobile_annual_xl') {
      display: none;
    }

    .categories-select-text {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;

      span {
        font-size: 21px;
        line-height: 30.87px;
        color: #fff;
        font-family: $main-font_medium;
      }

      img {
        display: block;
        width: 13px;
      }
    }
    .categories-select-dropdown {
      position: absolute;
      width: auto;
      top: 100%;
      left: 0;
      padding: 8px 16px;
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.3s;
      &.open {
        opacity: 1;
        visibility: visible;
      }

      .dropdown-item {
        white-space: nowrap;
        span {
          display: inline-block;
          position: relative;
          padding: 8px 0;
          font-size: 21px;
          line-height: 30.87px;
          color: #fff;
          font-family: $main-font_medium;
          &:after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: transparent;
            transition: all ease 0.3s;
          }
          &.active {
            font-family: $main-font_bold;
            &:after {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}
