@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.inner-banner {
  margin-bottom: 8px;
  display: none;

  .banner-container {
    padding: 0 12px 12px;
    background-color: #171717;

    .title {
      display: flex;
      width: 100%;
      min-height: 35px;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 26.46px;
      color: white;
    }

    .banner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }

  &.rendered {
    display: block;
  }
}
