@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.recomended-item {
  position: relative;
  padding-bottom: 16px;
  width: 25%;
  flex-grow: 1;

  @include screen('mobile_xl') {
    width: 50%;
    padding-bottom: 6px;
  }

  &.active {
    background-color: #171717;

    .cross-icon {
      transform: translate(3px, -3px) rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.main-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 346px;
  background-color: $black-color;
  color: white;
  cursor: pointer;
  overflow: hidden;

  @include screen('mobile_xl') {
    height: 361px;
  }

  @include screen('mobile_lg') {
    height: 325px;
  }

  @include screen('mobile_sm') {
    height: 289px;
  }

  @media screen and (min-width: 480px) {
    height: 361px;
  }
}

.cover {
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 230px;
  background-color: #eee;
  background-size: cover;
  background-position: center;

  @include screen('mobile_xl') {
    height: 233px;
  }

  @include screen('mobile_lg') {
    height: 188px;
  }

  @include screen('mobile_sm') {
    height: 153px;
  }

  @media screen and (min-width: 480px) {
    height: 233px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.info {
  padding: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  @include screen('mobile_xl') {
    padding: 10px 8px 8px;
  }

  @include screen('mobile_lg') {
    padding: 8px 8px 13.2px 8px;
  }

  @include screen('mobile_sm') {
    padding: 8px;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sponsored {
      background-color: white;
      color: #171717;
      font-size: 14px;
      line-height: 15.47px;
      padding: 0 2px;

      @include screen('mobile_xl') {
        font-size: 14px;
        line-height: 16px;
        padding: 2px;
      }

      @include screen('mobile_sm') {
        font-size: 14px;
        line-height: 15.47px;
        padding: 0 2px;
      }
    }

    .cross-icon {
      position: relative;
      width: 16.5px;
      height: 16.5px;
      transform: translate(3px, -3px);

      @include screen('mobile_xl') {
        width: 16.5px;
        height: 16.5px;
      }

      @include screen('mobile_lg') {
        width: 15px;
        height: 15px;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include screen('mobile_xl') {
          height: 5px;
        }

        @include screen('mobile_lg') {
          height: 3px;
        }
      }

      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}

.artist-and-title {
  margin-bottom: 18.31px;

  h3,
  h4 {
    margin: 0;
  }

  .title {
    font-family: $main-font_bold;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
    text-align: right;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;

    @include screen('tablet') {
      font-size: 20px;
      line-height: 20px;
    }

    @include screen('mobile_xl') {
      font-size: 18px;
      line-height: 18px;
    }
  }

  .artist {
    font-size: 15.47px;
    font-weight: 400;
    line-height: 14.48px;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;

    @include screen('tablet') {
      font-size: 18px;
      line-height: 18px;
    }

    @include screen('mobile_xl') {
      font-size: 16px;
      line-height: 17px;
    }
  }
}

.player {
  position: relative;
  border-top: 1px solid $black-color;
  padding: 16px;
  background-color: $black-color;
  color: white;
  border-bottom: 16px solid white;

  @include screen('tablet') {
    padding: 23.16px 26.11px 12.63px;
  }

  @include screen('mobile_xl') {
    border-bottom-width: 6px;
  }

  @include screen('mobile_xl') {
    padding: 12.5px 10px;
  }

  &-embed {
    position: relative;
    height: 152px;
    margin-bottom: 14px;

    @include screen('tablet') {
      margin-bottom: 16.83px;
    }

    @include screen('mobile_lg') {
      height: 80px;
    }

    iframe {
      display: block;
      border: 0;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .platforms {
      display: flex;
      align-items: center;
      gap: 14px;

      @include screen('tablet') {
        height: 40px;
      }

      @include screen('mobile_xl') {
        height: 24px;
        gap: 12px;
      }

      button {
        width: 31px;
        height: 31px;
        transition: opacity;
        transition-duration: 0.35s;

        @include screen('tablet') {
          width: 31px;
          height: 31px;
        }

        @include screen('mobile_xl') {
          width: 24px;
          height: 24px;
        }

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          opacity: 0.8;
        }

        &.platform-YT {
          width: 44px;
          height: 31px;

          @include screen('mobile_xl') {
            width: 35px;
            height: 24px;
          }
        }

        &.platform-apple-music {
          width: 33px;
          height: 31px;

          @include screen('mobile_xl') {
            width: 26px;
            height: 24px;

            img {
              width: 26px;
              height: 24px;
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      gap: 14px;

      button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        padding: 0 10px;
        border: 1px solid white;
        font-family: $main-font_bold;
        font-size: 16px;
        line-height: 24px;
        transition: opacity;
        transition-duration: 0.35s;

        &:hover {
          opacity: 0.8;
        }

        @include screen('mobile_xl') {
          font-size: 14px;
          line-height: 21px;
          height: 24px;
        }
      }
    }
  }
}
