.main {
  position: relative;
}

.fake-preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
}
