@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.first-place {
  overflow: visible;
  transform: translate(10px, 0) scale(1);

  @include screen('mobile_lg') {
    transform: translate(-3px, 15px) scale(0.75);
  }

  text {
    font-family: $secondary-font;
    font-size: 85px;
    fill: white;

    @include screen('mobile_lg') {
      font-size: 100px;
    }
  }

  image {
    transform-origin: center;
    transform: rotate(0deg) translate(0, 0) scale(1);

    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-duration: 8s;
    animation-timing-function: linear;

    @keyframes rotate {
      0% {
        opacity: 0;
        transform: rotate(0deg) translate(0, 0) scale(2, 1);
      }
      5% {
        opacity: 1;
      }
      45% {
        opacity: 1;
      }
      50% {
        opacity: 0;
        transform: rotate(720deg) translate(0, 0) scale(2, 1);
      }
      100% {
        opacity: 0;
        transform: rotate(720deg) translate(0, 0) scale(2, 1);
      }
    }
  }
}
