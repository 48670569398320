@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.mobile-navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 0;

  .navigation-links {
    display: flex;
    align-items: center;
    height: 100%;
    .navigation-item {
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 32px;
      white-space: nowrap;

      &:first-child {
        padding-right: 0;
      }

      &:last-child {
        padding-left: 20px;
      }

      a {
        text-decoration: none;

        span {
          display: inline-block;
          position: relative;
          font-size: 21px;
          line-height: 30.87px;
          color: #fff;
          font-family: $main-font_medium;
          white-space: nowrap;
          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: transparent;
            transition: all ease 0.3s;
          }
          &.active {
            font-family: $main-font_bold;
            &:after {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}
