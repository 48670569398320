@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.socials {
  position: relative;
  height: 100%;
  background-color: #000;

  &.open {
    .btns {
      transform: translateY(0);
    }
  }

  @include screen('mobile_xl') {
    width: 54px;
    background-color: $black-color;
  }

  .toggle-btn {
    display: none;
    background-color: inherit;

    @include screen('mobile_xl') {
      position: relative;
      z-index: 2;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      display: block;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    gap: 30px;
    height: 100%;

    @include screen('mobile_xl') {
      position: absolute;
      z-index: 1;
      flex-direction: column;
      padding: 24px 0;
      gap: 24px;
      top: 100%;
      left: 0;
      width: 100%;
      height: auto;
      background-color: inherit;
      transform: translateY(-100%);
      transition: transform;
      transition-duration: 0.35s;
    }

    button {
      display: block;
      transition: opacity;
      transition-duration: 0.35s;

      img {
        display: block;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
