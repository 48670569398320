@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.shares-wrapper {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  height: 52px;

  @include screen('mobile_annual_xl') {
    display: block;
  }

  .share-menu-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 19px;
    }
  }

  .shares {
    width: 100%;
    height: 0;
    background-color: $black-color;
    transition: all ease 0.3s;
    overflow: hidden;

    &.open {
      height: 156px;
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      img {
        display: block;
        width: 34px;
      }
    }
  }
}
